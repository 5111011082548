
.followSocialNetwork {
  display: flex;
  align-items: center;
  padding-inline-start: 0;

  .item {

    :hover {
      color: #009e66;
      transition: 0.4s;
    }
  }


  &::before {
    background-color: rgba(255, 255, 255, .25);
    content: '';
    height: 2px;
    margin-right: 23px;
    width: 60px;
    @media (max-width: 483px) {
      display: none;
    }
  }

  li {
    list-style: none;
    padding-left: 0;
    color: white;
  }


}


