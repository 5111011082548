
.navBarMenu{
  display: flex;
  right: 40px;
  flex-direction: column;
  height: 19px;
  justify-content: space-between;
  position: fixed;
  width: 30px;
  flex-wrap: nowrap;
  align-items: flex-end;
  &:before{
    background-color: #f3f3f3;
    content: '';
    width: 85%;
    height: 3px;
    cursor: pointer;
  }
  &:after{
    background-color: #f3f3f3;
    content: '';
    width: 100%;
    height: 3px;
    cursor: pointer;
  }

  .line{
    background-color: #f3f3f3;
    width: 63%;
    height: 3px;
    cursor: pointer;

  }

}
.navBarMenuOpen{
  background-color: #191919;
  position: fixed;
  top: 80px;
  right: 40px;
  width: 180px;
  height: 200px;
  margin: 0;
  display: flex;
  transform: translate(0, 5px);
  transition: 1s;
  @media (min-width: 768px) and (max-width: 10000px) {
    display: none;
  }
}

