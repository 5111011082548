@import "src/common/styles/sass/wariables.sass";

.hoverEffectScale {
    list-style:none;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    padding: 0;
}
.hoverEffectScale li {
    position: relative;
    display: inline-block;
    /*background-color: #5e0505;*/
    margin: 0;
    /*border: 5px solid #fff;*/
    box-shadow: 1px 3px 1px 0 rgba(0, 0, 0, 0.08);
    overflow: hidden;
}
/* Заголовок, текст и ссылка */
.hoverEffectScale li h2{
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.8;
    margin-bottom: 10px;
}

/*кнопка*/
.hoverEffectScale li a {
    text-decoration: none;
    color:$colorElementsDecoration;
    margin: 0 auto;
    transition: all 0.3s ease-in-out;
    display: inline-block;
    font-weight: 600;
    text-align: center;
    /*visibility: hidden;*/
}
.hoverEffectScale li a:hover {
    /*background: #454545;*/
    color: $colorElementsDecorationHover
}
/* Выезжающий блок с текстом */
.hoverEffectScale li > div {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.hoverEffectScale li:hover > div {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
}
.hoverEffectScale li div {
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.89); /* Фон блока при наведении */
    transition: all 0.4s ease-in-out;
    color: #b41919;
}
.hoverEffectScale li img {
    transition: all 0.2s linear;
}
.hoverEffectScale li:hover img {
    transform: scale(1.3);
}
.hoverEffectScale li:hover div {
    opacity: 1;
}