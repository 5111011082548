
$textColorTitle: #f0f0f0;
$textColorTitleDescription: #9f9f9f;

$bgColor: #111010;

$colorElementsDecoration: #009e66;
$colorElementsDecorationHover: #009e40;

$fontFamilyText: Poppins, sans-serif;
