@import "src/common/styles/sass/wariables";


.projectsBlock {
  background-color: $bgColor;

  .projectContainer {
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    .projects {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: 576px) and (max-width: 991.80px) {
        justify-content: center;
      }
      @media (max-width: 575.80px) {
        display: grid;
        grid-template-columns: repeat(auto-fill, 50%);
      }
    }

  }
}

