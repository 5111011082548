@import "src/common/styles/sass/wariables.sass";
@import "src/common/styles/sass/mixins";

.formBlock {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 670px;
  //width: 80%;
  color: #9f9f9f;


  @media (min-width: 992px) and (max-width: 1200px) {
    width: 550px;
  }
  @media (min-width: 576px) and (max-width: 767.80px) {
    max-width: 546px;
  }
  @media (max-width: 575.80px) {
    width: 95%;
  }

  .contactForm {
    position: relative;
    display: block;
    margin-top: 0;
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus
    input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border: 0;
      -webkit-text-fill-color: #777;
      -webkit-box-shadow: 0 0 0 1000px #161616 inset;
      transition: background-color 5000s ease-in-out 0s;
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(0,174,255,0.04) 50%,rgba(255,255,255,0) 51%,rgba(0,174,255,0.03) 100%);
    }
    .title {
      @include text(1.5rem, 1.5, 500);
      color: $textColorTitle;
      margin: 0 0 22px 0;
      text-align: left;

    }

    .flex {
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;

      .formGroupMini {
        position: relative;
        width: 50%;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        margin-bottom: 25px;
        @media (max-width: 767.80px) {
          width: 100%
        }
        .errorTextName {
          display: inline-block;
          font-weight: 600 ;
          min-width: 150px;
          min-height: 20px;
          position: absolute;
          color: #f6f6f6;
          //border: 1px solid black;
          border-radius: 5px;
          z-index: 10;
          padding: 2px 7px;
          opacity: 100%;
          left: 25%;
          top: 120%;
          background-color: #565656;
          transition: 0.5s;
          font-size: 15px;
          @media (max-width: 767.80px) {
            top: 95%;
            left: 30%;
          }
          &::after{
            content: '';
            position: absolute;
            left:50%;
            margin-left: -5px;
            top: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #565656;


        }

          &:hover {
            opacity: 60%;
          }
        }

        .errorEmail {
          display: inline-block;
          font-weight: 600 ;
          min-width: 150px;
          min-height: 20px;
          position: absolute;
          color: #f6f6f6;
          //border: 1px solid black;
          border-radius: 5px;
          z-index: 10;
          padding: 2px 7px;
          opacity: 100%;
          left: 25%;
          top: 120%;
          background-color: #565656;
          transition: 0.5s;
          font-size: 15px;
          @media (max-width: 767.80px) {
            top: 95%;
            left: 33%;
          }
          &::after{
            content: '';
            position: absolute;
            left:50%;
            margin-left: -5px;
            top: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #565656;
          }
        }

        .formControl {
          background-color: #161616;
          border: none;
          border-bottom: 1px solid #1d1d1d;
          border-radius: 0;
          font-size: 15px;
          height: 44px;
          padding: 9px 14px;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          color: #777;
          width: 100%;
          font-family: Poppins, sans-serif;
          font-weight: 400;
          line-height: 1.95;
          text-align: left;

          @media (max-width: 767.80px) {
            margin-bottom: 12px;
          }

          &:focus {
            outline: 0;
            outline-offset: 0;
            border-bottom: 1px solid #00d187;
          }
        }
      }


      .formGroupStandard{
        margin-bottom: 25px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        color: #9f9f9f;
        .formControlStandard{
          background-color: #161616;
          border: none;
          border-bottom: 1px solid #1d1d1d;
          border-radius: 0;
          font-size: 15px;
          height: 44px;
          padding: 9px 14px;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          color: #777;
          width: 100%;
          font-family: Poppins, sans-serif;
          font-weight: 400;
          line-height: 1.95;
          text-align: left;
          @media (max-width: 767.80px) {
            margin-bottom: 12px;
          }
          &:focus{
            outline: 0;
            outline-offset: 0;
            border-bottom: 1px solid #00d187;
          }
        }
        .textSubject {
          display: inline-block;
          font-weight: 600 ;
          min-width: 150px;
          min-height: 20px;
          position: absolute;
          color: #f6f6f6;
          //border: 1px solid black;
          border-radius: 5px;
          z-index: 10;
          padding: 2px 7px;
          opacity: 100%;
          left: 35%;
          top: 120%;
          background-color: #565656;
          transition: 0.5s;
          font-size: 15px;
          @media (max-width: 767.80px) {
            top: 95%;
            left: 25%;
          }
          &::after{
            content: '';
            position: absolute;
            left:50%;
            margin-left: -5px;
            top: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #565656;
          }
        }

      }



      .formMessage {
        margin-bottom: 25px;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;

        textarea {
          overflow: auto;
          resize: vertical;
          margin: 0;
          min-height: 130px;
          background-color: #161616;
          border: none;
          border-bottom: 1px solid #1d1d1d;
          border-radius: 0;
          font-size: 15px;
          padding: 9px 14px;
          transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
          color: #777;
          width: 100%;
          font-family: Poppins, sans-serif;
          font-weight: 400;
          line-height: 1.95;
          text-align: left;


          @media (max-width: 767.80px) {
            margin-bottom: 12px;
          }
          &:focus{
            outline: 0;
            outline-offset: 0;
            border-bottom: 1px solid #00d187;
          }
        }

        .messageError {
          display: inline-block;
          font-weight: 600 ;
          min-width: 150px;
          min-height: 20px;
          position: absolute;
          color: #f6f6f6;
          //border: 1px solid black;
          border-radius: 5px;
          z-index: 10;
          padding: 2px 7px;
          opacity: 100%;
          left: 50%;
          top: 105%;
          background-color: #565656;
          transition: 0.5s;
          font-size: 15px;
          @media (max-width: 767.80px) {
            top: 97%;
            left: 46%;
            min-width: 10px;
          }
          @media (max-width: 559.80px) {
            top: 97%;
            left: 65%;
            width: 100px;
          }
          &::after{
            content: '';
            position: absolute;
            left:50%;
            margin-left: -5px;
            top: -7px;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #565656;
          }
        }

      }


      .submit{
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        @media (max-width: 767.80px) {
          margin-bottom: 10px;
        }

        .buttonForm{
          //width: 200px;
          margin-bottom: 30px;
          padding: 10px 26px;
          color: #ffffff;
          text-decoration: none;
          margin-right: 23px;
          background-color: #009e66;
          border: 1px solid #009e66;
          border-radius: 30px;
          font-size: 14px;
          font-family: Poppins, sans-serif;
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          transition: color .10s ease-in-out,background-color .10s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          user-select: none;
          vertical-align: middle;
          cursor: pointer;
          &:hover{
            background-color: #008c5c ;
          }
        }

        .buttonFormStatusLoading{
          width: 160px;
          margin-bottom: 30px;
          padding: 10px 26px;
          color: rgba(16, 16, 16, 0.3);
          text-decoration: none;
          margin-right: 23px;
          background-color: rgba(239, 239, 239, 0.3);
          border: 1px solid rgba(105, 105, 105, 0.3);
          border-radius: 30px;
          font-size: 14px;
          font-family: Poppins, sans-serif;
          font-weight: 500;
          line-height: 1.5;
          text-align: center;
          transition: color .10s ease-in-out,background-color .10s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
          user-select: none;
          vertical-align: middle;
          cursor: pointer;
          //opacity: 0.5
        }

      }




    }
  }
}