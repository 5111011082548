.fluidAnimation{
  position: absolute;
  z-index: 1;
  cursor: pointer;
}



.main {
  //background-image:  url("#{'asd'}");
  height: calc((1vh) * 100);
  background-color: rgb(17, 17, 17);
  overflow: hidden;
  padding: 0 50px;


  .particle{
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0.8;

  }

  .container {
    position: relative;
    height: 100% !important;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;

    .containerCenter {
      height: 100% !important;
      align-items: center !important;
      justify-content: center !important;
      display: flex;
      flex-wrap: wrap;
      margin-left: -15px;
      margin-right: -15px;
      box-sizing: border-box;

      .textCenter {
        text-align: center !important;
        padding-left: 15px;
        padding-right: 15px;
        position: relative;
        width: 100%;
        font-family: Poppins, sans-serif;
        z-index: 2;
        .name {
          color: #fff;
          font-size: 70px;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 34px;
          line-height: 1.2;
          margin-top: 0;

          @media (min-width: 605px) and (max-width: 991.80px) {
            font-size: 62px ;
          }
          @media (min-width: 516px) and (max-width: 604.80px) {
            font-size: 50px ;
          }
          @media (min-width: 0px) and (max-width: 515.80px) {
            font-size: 36px ;
          }
        }

        .headLine {
          color: #fff;
          font-size: 32px;
          font-weight: 400;
          letter-spacing: 1.2px;
          margin: 0;
          padding: 0;
          span {
            &:nth-child(1){
              margin-left: 3px;
            }
          }
          @media (min-width: 605px) and (max-width: 991.80px) {
            font-size: 30px ;
          }
          @media (min-width: 516px) and  (max-width: 604.80px) {
            font-size: 24px ;
          }
          @media (min-width: 0px) and (max-width: 515.80px) {
            font-size: 18px ;
          }
          .singleHeadLine {
            display: inline-block;
            position: relative;
            text-align: left;
            vertical-align: top
          }

        }

      }

      .fixedBlock {
        @media (min-width: 0px) and (max-width: 767.80px) {
          display: none;
        }

      }

    }
  }
}


