@import "src/common/styles/sass/wariables";

.footerBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $bgColor;
  min-height: 45vh;


  .footerContainer {

    align-content: stretch;
    padding: 40px 0 0 0;
    position: relative;
    width: 100%;
    display: inline-block;
    text-align: center;
    .hrFooter {
      position: absolute;
      width: 82%;
      height: 2px;
      background: rgba(255, 255, 255, 0.05);
      margin: 0 auto;
      left: 0;
      right: 0;
      margin-top: -90px;
    }

    .center {
        position: relative;

      .footerLogo {
        margin-left: 30px;
        margin-bottom: 40px;
        color: #fff;
        img{
          width: 80px;
          height: auto;
        }
      }

      .siteSocial {
        list-style: none;
        margin: 0 0 30px 0;
        padding: 0;

        li {
          display: inline-block;
          text-align: center;
          margin-bottom: 10px;

          a {
            text-decoration: none;
            display: inline-block;
            padding-left: 30px;
            padding-right: 30px;
            color: rgba(255, 255, 255, 0.5);
            letter-spacing: .1rem;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            outline: none;
            text-align: center;
            @media (min-width: 768px) and (max-width: 991.80px) {
              padding-left: 18px;
              padding-right: 18px;
            }
            @media (min-width: 576px) and (max-width: 767.80px) {
              padding-left: 10px;
              padding-right: 10px;
            }
          }
        }
      }

      .footerText {
        color: #BBBBBB;
        font-family: "Poppins", sans-serif;
        -webkit-font-smoothing: antialiased;
        letter-spacing: .025em;
        line-height: 1.5;

        small {
          font-size: 70%;
          font-weight: 400;
          margin-left: 30px;
          text-align: center;
        }
      }
    }


  }
}