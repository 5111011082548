@import "src/common/styles/sass/wariables";
@import "src/common/styles/sass/mixins";

.skill {
  width: 370px;
  height: auto;
  box-sizing: border-box;
  background-color: #161616;
  border-bottom: 2px solid transparent;
  box-shadow: 0 6px 16px rgba(0, 0, 0, .2);
  margin-bottom: 30px;
  padding: 38px 42px;
  transition: all .1s ease-in-out;
  @media (min-width: 992px) and (max-width: 1200px) {
    flex: 0 0 31.33333%;
    width: 31.33333%;
  }
  @media (min-width: 768px) and (max-width: 991.80px) {
    margin: 10px;
    width: 354px;
  }
  @media (min-width: 576px) and (max-width: 767.80px) {
    padding: 38px 42px;
    min-width: 546px;
  }
  @media (max-width: 575.80px) {
    //width: 300px;
    //padding: 38px 42px;
    width: 95%;
    //height: auto;
  }
  @media (max-width: 385px) {

  }
  &:hover{
    border-bottom-color: $colorElementsDecoration
  }

  .icon {
    display: flex;
    width: 50px;
    height: 50px;
    .elementIcon{
      width: 50px;
      height: 50px;
    }
  }


  .title {
    @include text(20px,1.2,500);
    letter-spacing: -.5px;
    margin-bottom: 14px;
    color: #f3f3f3;
  }

  .description {
    @include text(14px,1.95,400);
    word-break: break-all;
    text-align: left;
  }
}






