.iconsMouse{
  z-index: 3;
  position: absolute;
  display: inline-flex;
  align-items: center;
  left:20px;
  top: 50px;
  gap: 10px;
  animation: blink 2s ease-in-out infinite;
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
  }
  .icon{
    //z-index: 3;
    color: #ffffff;
    font-size: 25px;
  }
  .iconText{
    //z-index: 3;
    color: white;
  }
}