@import "src/common/styles/sass/wariables";
@import "src/common/styles/sass/mixins";

.contact {
  padding-left: 15px;
  padding-right: 15px;
  width: 455px;
  @media (min-width: 992px) and (max-width: 1200px) {
    width: 44%;
  }
  @media (min-width: 768px) and (max-width: 991.80px) {
    width: 650px;
  }
  @media (min-width: 576px) and (max-width: 767.80px) {
    width: 475px;
  }
  @media (max-width: 576px) {
    width: 100%;
  }
  .contactInfo {
    padding-left: 15px;

    .title {
      @include text(1.5rem, 1.5, 500);
      color: $textColorTitle;
      margin: 0 0 12px 0;
      text-align: left;
    }

    .description {
      margin-bottom: 23px;
      margin-top: 0;
      @include text(15px, 1.95, 400);
      @media (max-width: 1200px) {
        font-size: 14px;
      }

    }

  }
}