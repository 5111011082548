@import "src/common/styles/sass/wariables";


.profileInfo {
  font-size: 15px;
  padding: 29px 0;
  font-style: normal;
  @media (min-width: 768px) and (max-width: 991.80px) {
    //margin: 0 100px 0 100px;
    text-align: center;
  }

  .row {
    display: flex;
    margin-bottom: 0;

    .rowSingleInfo {
      //position: relative;
      width: 100%;
      margin: 0 0 10px;
      @media (min-width: 992px) and (max-width: 1200px) {
        font-size: 14px;
        width: 100px;
      }
      span {
        color: #DADADA;
        margin-right: 10px;
        font-weight: 400;
      }

      p {
        font-size: 15px;
        display: inline-block;
        margin-bottom: 0;
        margin-top: 0;
        box-sizing: border-box;
        border: none;
        line-height: inherit;
        font-family: Poppins, sans-serif;
        font-weight: 400;
        color: $textColorTitleDescription;

      }

      a {
        color: $colorElementsDecoration;
        text-decoration: none;

        &:hover {
          color: #005235;
          transition: all .15s ease-in-out
        }
      }
    }
  }

}