@import "src/common/styles/sass/wariables.sass";

.contactsBlock {
  min-height: 100vh;
  background-color: $bgColor;

  .contactsContainer {
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    .forms {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media  (max-width: 991.80px) {
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        flex-direction: column;
      }
      @media  (max-width: 767.80px) {
        flex-direction: column;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        align-items: center;
      }


    }
  }
}



