@import 'src/common/styles/sass/wariables';


.skillsBlock {
  background-color: $bgColor;

  .skillsContainer {
    flex-direction: column;
    justify-content: flex-start;
    min-height: 100vh;

    .skills {
      //margin-top: 50px;
      //width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      @media (min-width: 0px) and (max-width: 991.80px) {
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}





