

* {
    box-sizing: border-box;
}

.svg-icon {
    fill: #000;
    transition: fill 0.3s ease;
}

.svg-icon:hover {
    fill: #f00;
}

::selection {
    background-color: #009e66;
    color: #fff
}