@import "src/common/styles/sass/wariables";
@import "src/common/styles/sass/mixins";


.mainBlock {
  background-color: $bgColor;
  box-sizing: border-box;
  min-height: 100vh;

  .mainContainer {
    flex-direction: column;
    justify-content: flex-start;

    .main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      margin-left: -15px;
      margin-right: -15px;
      @media (min-width: 0px) and (max-width: 991.80px) {
        justify-content: center;
      }

      .photo {
        width: 500px;
        height: auto;
        padding: 15px 15px 15px 0;
        @media (min-width: 992px) and (max-width: 1200px) {
          width: 450px;
        }
        @media (max-width: 991.98px) {
          padding: 15px 15px 15px 15px;
        }

        img {
          width: 430px;
          height: auto;
          @media (min-width: 992px) and (max-width: 1200px) {
            height: 490px;
            width: 430px;
            object-fit: cover;
          }
          @media (max-width: 991.98px) {
            border-radius: 50%;
            display: block;
            height: 250px;
            margin: 0 auto 40px auto;
            object-fit: cover;
            width: 250px;
          }
          @media (max-width: 767.80px) {
            border-radius: 50%;
            display: block;
            height: 200px;
            margin: 0 auto 40px auto;
            object-fit: cover;
            width: 200px;
          }
        }
      }

      .profile {
        max-width: 670px;
        @media (min-width: 992px) and (max-width: 1200px) {
          max-width: 530px;
        }
        @media (min-width: 576px) and (max-width: 767.80px) {
          max-width: 530px;
          padding-left: 12px;
        }
        @media (min-width: 320px) and (max-width: 575.80px) {
          max-width: 530px;
        }


        h2 {
          @include text(24px, 1.2, 500);
          color: $colorElementsDecoration;
          margin: 0 0 15px;
          @media (min-width: 992px) and (max-width: 1200px) {
            font-size: 24px;
          }
          @media (min-width: 0px) and (max-width: 991.80px) {
            font-size: 18px;
          }
        }

        h6 {
          @include text(31px, 1.6, 700);
          color: $textColorTitle;
          margin: 0 0 16px;
          @media (min-width: 992px) and (max-width: 1200px) {
            font-size: 26px;
          }
          @media (min-width: 576px) and (max-width: 991.80px) {
            font-size: 26px;

          }
          @media (max-width: 575.80px) {
            font-size: 22px;
          }
        }

        .text {
          @include text(15, 1.95, 400);
          border-bottom: 2px solid rgba(255, 255, 255, .08);
          margin-bottom: 0;
          padding: 0 0 29px;
          word-wrap: break-word;
          @media (min-width: 576px) and (max-width: 1200px) {
            padding: 0 0 20px;
            font-size: 14px;
          }
          @media (max-width: 767.80px) {
            font-size: 14px;
          }
        }

        .cvAndSocialNetworks {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          @media (min-width: 768px) and (max-width: 991.80px) {
            justify-content: center;
          }
          @media (max-width: 393.80px) {
            justify-content: center;
          }
          .btn {
            @media (max-width: 393.80px) {
              margin-bottom: 15px;

            }
          }

        }
      }
    }

  }
}








