@import "src/common/styles/sass/wariables.sass";

.project {


  .imageProjectAndBtn {

  }
   .buttonOpenProject {
     text-decoration: none;
     cursor: pointer;
     //outline: none;
     //background-color: rgba(28,28,28,0);
     //border: 1px solid white;
     //color:$colorElementsDecoration;
     margin: 0 auto;
     transition: all 0.3s ease-in-out;
     display: inline-block;
     font-weight: 600;
     text-align: center;
   }


  .imageProjectAndBtn img {
    width: 370px;
    height: auto;
    vertical-align: middle;
    @media (min-width: 992px) and (max-width: 1200px) {
      max-width: 310px;
    }
    @media (min-width: 768px) and (max-width: 991.80px) {
      max-width: 354px;
      margin: 10px;
    }
    @media (min-width: 576px) and (max-width: 767.80px) {
      max-width: 258px;
      margin: 10px;
    }
    @media (max-width: 575.80px) {
      max-width: 95%;
    }
  }
}

