@import "src/common/styles/sass/wariables";

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background-color: #111;
  padding: 23px 50px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  //z-index: 1030;

  .displayNoneNavigate {
  z-index: 1030;
    @media (max-width: 767.80px) {
      display: none;
    }
  }

  .displayActiveNavigate {
    z-index: 1030;
    @media (min-width: 768px) and (max-width: 10000px) {
      display: none;
    }
    @media (max-width: 767.80px) {
      display: inline-block;
    }
  }

  .navBarBrand {
    display: block;
    margin-right: 0;
    padding-bottom: 13px;
    padding-top: 0;
    white-space: nowrap;
    z-index: 1030;
    img {
      width: 80px;
      height: auto;
    }
  }

}